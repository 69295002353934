import React from 'react'
import {Routes, Route} from 'react-router-dom'
import HomePage from './containers/pages/HomePage'
import RentPage from './containers/pages/rent'
import BuyPage from './containers/pages/buy'
import SellPage from './containers/pages/sell/SellPage'
import AgentRegistrationPage from './containers/pages/agent/AgentRegistrationPage'
import FindAgentPage from './containers/pages/agent/FindAgentPage'
import LeadsPage from './containers/pages/agent/LeadsPage'
import BuyCreditsPage from './containers/pages/credit/BuyCreditsPage'
import AboutPage from './containers/pages/AboutPage'
import TermsPage from './containers/pages/TermsPage'
import ViewLeadPage from './containers/pages/ViewLeadPage'
import ContactUsPage from './containers/pages/ContactUsPage'
import CompletePage from './containers/pages/CompletePage'

import GlobalError from './containers/pages/errors/GlobalError'
import {ErrorHandler} from './contexts/ErrorHandler'

import MainLayout from './components/Layout'

const App = () => {
  return (
    <MainLayout>
      <ErrorHandler>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/rent" element={<RentPage />} />
          <Route path="/buy" element={<BuyPage />} />
          <Route path="/sell" element={<SellPage />} />
          <Route path="/agent" element={<AgentRegistrationPage />} />
          <Route path="/find" element={<FindAgentPage />} />
          <Route path="/get" element={<LeadsPage />} />
          <Route path="/credits" element={<BuyCreditsPage />} />
          <Route path="/about" element={<AboutPage />} />
          {/* <Route path="/help" element={<HelpPage />} /> */}
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/thanks" element={<CompletePage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/leads" element={<ViewLeadPage />} />
          <Route element={<GlobalError status={404} />} />
        </Routes>
      </ErrorHandler>
    </MainLayout>
  )
}

export default App
