import React from 'react'
import {Button} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import wiredBlue from 'assets/images/wired-blue.gif'

const copy = {
  rentPlanHeading: '9. When are you planning to move in?',
  rentPlanHelpText: `How soon is the move likely going to be?`,
  next: 'Next',
  back: 'Back',
}

const StepRentPlan = ({handleChange, handleBlur, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">{copy.rentPlanHeading}</h3>
      <div className="form-group">
        <select name="rentPlan" onChange={handleChange}>
          <option>Please select</option>
          <option value="ready">I am ready now</option>
          <option value="browsing">Just browsing around</option>
          <option value="other">Other</option>
        </select>
      </div>

      {values?.rentPlan === 'other' && (
        <div className="form-group">
          <input
            type="text"
            name="rentPlanOther"
            value={values.rentPlanOther}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Type other answer here"
          />
        </div>
      )}

      <div className="explaina text-center col-md-8 offset-md-2">{copy.rentPlanHelpText}</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredBlue} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps">
            <div className="rate" style={{width: '80%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back" onClick={previous}>
        {copy.back}
      </Button>
      <Button variant="primary" className="next" onClick={next}>
        {copy.next}
      </Button>
    </div>
  )
}

export default StepRentPlan
