import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

const TermsPage = () => {
  return (
    <section id="work" className="top-60">


           
        <div className="container terms">
          <div className="row">
            <div className="col-md-12">
              <h1 className="heading">Terms and conditions<br /></h1>
              <div className="col-md-12 wizard white-bg go-left big-pad relative">
                <div>
                  <p style={{marginBottom: '14pt', textAlign: 'left', lineHeight: 'normal', fontSize: '18pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Terms &amp; Conditions</span>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>for Customers</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>These Terms and Conditions govern the facilitation services that Askjojo.co.za will make available to you, and are in addition to the website terms of use that apply from time to time, to your use of the Website (as defined below).</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You should read these Terms and Conditions carefully before using our Website, as by using our Website and using the Facilitation Services, you are confirming that you accept these Terms and Conditions and that you will comply with them. If you do not accept these Terms and Conditions, you should leave our Website and cease using the Facilitation Services immediately.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>About us</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>We, Askjojo Pty Ltd (“we”, “us”, “Askjojo”) make the Facilitation Services available to you via the Website. We are a company registered in South Africa under company number 2023/790711/07, registered address Cresta Junction, Suite 215 Block B, Second Floor, Cnr Beyers Naude Drive and Judges Avenue, Cresta, Johannesburg, 2194.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You can contact us:</span>
                  </p>
                  <div style={{marginTop: '14pt', marginLeft: '18pt', backgroundColor: '#ffffff'}}>
                    <p style={{marginLeft: '18pt', marginBottom: '0pt', textIndent: '-18pt', lineHeight: 'normal', fontSize: '12pt', AwImport: 'list-item', AwListLevelNumber: 0, AwListNumberFormat: '""', AwListNumberStyles: '"bullet"', AwListPaddingSml: '13.4pt'}}>
                      <span style={{AwImport: 'ignore'}}>
                        <span style={{fontFamily: '"jost"', color: '#3b416d'}}>
                          <span style={{fontFamily: 'Symbol', fontSize: '10pt'}}></span>
                        </span>
                        <span style={{width: '13.4pt', font: '7pt "Times New Roman"', display: 'inline-block', AwImport: 'spaces'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                      </span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>By email at</span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>&nbsp;</span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>support@askjojo.co.za </span>
                    </p>
                  </div>
                  <div style={{marginLeft: '18pt', backgroundColor: '#ffffff'}}>
                    <p style={{marginLeft: '18pt', marginBottom: '0pt', textIndent: '-18pt', lineHeight: 'normal', fontSize: '12pt', AwImport: 'list-item', AwListLevelNumber: 0, AwListNumberFormat: '""', AwListNumberStyles: '"bullet"', AwListPaddingSml: '13.4pt'}}>
                      <span style={{AwImport: 'ignore'}}>
                        <span style={{fontFamily: '"jost"', color: '#3b416d'}}>
                          <span style={{fontFamily: 'Symbol', fontSize: '10pt'}}></span>
                        </span>
                        <span style={{width: '13.4pt', font: '7pt "Times New Roman"', display: 'inline-block', AwImport: 'spaces'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                      </span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>By phone at</span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>&nbsp;</span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>0115682153</span>
                      <a href="tel:+27875517012" style={{textDecoration: 'none'}} />
                    </p>
                  </div>
                  <div style={{marginLeft: '18pt', marginBottom: '14pt', backgroundColor: '#ffffff'}}>
                    <p style={{marginLeft: '18pt', marginBottom: '0pt', textIndent: '-18pt', lineHeight: 'normal', fontSize: '12pt', AwImport: 'list-item', AwListLevelNumber: 0, AwListNumberFormat: '""', AwListNumberStyles: '"bullet"', AwListPaddingSml: '13.4pt'}}>
                      <span style={{AwImport: 'ignore'}}>
                        <span style={{fontFamily: '"jost"', color: '#3b416d'}}>
                          <span style={{fontFamily: 'Symbol', fontSize: '10pt'}}></span>
                        </span>
                        <span style={{width: '13.4pt', font: '7pt "Times New Roman"', display: 'inline-block', AwImport: 'spaces'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                      </span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>By post at</span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>&nbsp;</span>
                      <span style={{fontFamily: '"jost"', color: '#3b416d'}}>Cresta Junction, Suite 215 Block B, Second Floor, Cnr Beyers Naude Drive and Judges Avenue, Cresta, Johannesburg, 2194</span>
                    </p>
                  </div>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>1.Definitions and Interpretation</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>1.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>In these Terms and Conditions, the following words and expressions have the following meanings:</span>
                  </p>
                  <table cellSpacing={2} cellPadding={0} style={{marginBottom: '0pt', borderSpacing: '1.5pt'}}>
                    <tbody><tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Request</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>a request from a Customer as referred to in clause 2.2 below;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Customer/you</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>businesses and consumers who use the Website to send a request to an Agent who can provide the professional services they require;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Customer Contract</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>the contract between the Agent and the Customer for the provision of the Services;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Facilitation Services</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>the provision by Askjojo, of an Agent’s contact details, as set out in clause 2.4;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>the Agent</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>means the person offering their professional services to Customers;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>The Services</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>the professional services provided by an Agent to a Customer;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>The Website</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>means</span>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>&nbsp;</span>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>www.askjojo.co.za </span>
                          </p>
                        </td>
                      </tr>
                    </tbody></table>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>2. The Facilitation Services</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> Askjojo operates the Website which has been established to facilitate introductions between potential customers and professional Agents for the provision of a wide range of services. Our separate website terms of use apply to your use of the Website.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> Customers will be able to search the Website to find potential Agents, and the Website algorithms will help identify Agents who may be able to meet the Customer’s requirements. When a Customer requires the service of an Agent, they are required to send a “Request”, and each Request will result in us putting you in touch with Agents who may be able fulfil the criteria you have provided.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>To use the Facilitation Services, you will be required to accept these Terms and Conditions following the instructions on the Website, and a contract will be formed between you and us for the provision of the Facilitation Services at that point. You will need to enter into a separate contract with any Agents you wish to instruct to provide you with Services. See below for more information.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.4.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> When you have submitted your Request, we will submit your details to all the Agents who may be able to fulfil the criteria that you provided. A maximum of six Agents will contact you directly and you can opt to contact the Agents yourself. Where we cannot identify any Agents who may be able to fulfil the criteria you submitted, you will be notified that this is the case, and given the opportunity to expand your requirements.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.5.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> You will be entitled to review the information you receive from the Agents, and may or may not decide to engage an Agent to provide the Services you require. We cannot in any way endorse or confirm that the Agents identified as a result of the Facilitation Service we offer are able to fulfil your requirements, and it will be your sole responsibility to engage with the Agent to establish whether they can meet your requirements prior to you entering into a Customer Contract. If you do engage an Agent to provide the Services, you will become a customer of the Agent and will enter into a Customer Contract with them. For the avoidance of doubt, the Services are provided to you by the Agent, and not by Askjojo.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.6.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> Askjojo shall provide a link from the Website to the Agent’s own website (where applicable) , and shall include a facility for Customers to review an Agent’s performance which shall be available on the Website for other prospective customers to see.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.7.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> Reviews should be honest, based on your own experience, and should not contain racist or sexist content, content which is otherwise offensive, content which condescends certain people or groups, copyrighted material, unlawful content, any personal information or any information which you know to be inaccurate or false. We reserve the right to remove reviews which do not comply with these guidelines, and may ban you from leaving further reviews in the future. Please see our Website Terms and Conditions for further detail.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.8.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> Askjojo has no influence or control whatsoever on the information contained on the Agent’s website and accepts no liability for its content. The content of such third-party websites is the responsibility of the respective Agent. You will need to contract directly with the Agent for any Services you require from them.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.9.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> The Facilitation Services shall be provided to you at no cost.</span>
                    <br />
                    <br />
                    <span style={{fontFamily: '"jost"', fontWeight: 500, fontSize: '12pt', color: '#111637'}}>Important:</span>
                    <br />
                    <span style={{fontFamily: '"jost"', fontWeight: 500, fontSize: '12pt', color: '#111637'}}>Askjojo acts only as a facilitator of the introductions referred to above, and the provision of any Services by an Agent to a Customer will be subject to an entirely separate legal contract. If there are any issues with performance of those Services by the Agent, you will have legal rights of redress against the Agent directly, and not against Askjojo.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>3. Ending our contract</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You can always end your contract with us, and you can do this by ceasing to use the Facilitation Services. If you have opened a customer account on the Website, you will need to contact us by email, phone or post using the details listed above.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>4.Data Protection</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Please see our</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', textDecoration: 'underline', color: '#2d7af1'}}>Privacy Policy</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>and</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', textDecoration: 'underline', color: '#2d7af1'}}>Cookie Policy</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>for more information on how your personal information will be used.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>5.Liability</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>As provided for in Clause 2 above, you acknowledge that the contract for the provision of the Services is between you and the Agent and as such, it is the Agent who is responsible for the provision of the Services to you.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontWeight: 500, fontSize: '12pt', color: '#3b416d'}}>Askjojo shall have no liability to you whatsoever in respect of the provision of the Services and / or your dealings with any of the Agents who are listed on the Website.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}> Nothing in these Terms and Conditions limits or excludes our liability to you for:</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.2.1 </span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>death or personal injury resulting from negligence;</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.2.2 </span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>fraud or fraudulent misrepresentation; or</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.2.3 </span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>any other matter for which we are not permitted by law to exclude or limit our liability.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>We accept no liability for the information contained on any third-party websites linked to on our Website and we won't be liable for any loss or damage that may arise from your use of them.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '12pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>If you are an individual consumer</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.5.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Please note that we only provide Facilitation Services to consumers for domestic and private use. You agree not to use the Facilitation Service for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '12pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>If you are a business customer</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.6.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>We exclude all conditions, warranties, representations or other terms which may apply to this website or any content on it, whether express or implied.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.7.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with the Facilitation Services. In particular, we will not be liable for:</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.7.1 </span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>loss of profits, sales, business, or revenue;</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.7.2 </span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>business interruption;</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.7.3 </span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>loss of anticipated savings;</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.7.4 </span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>loss of business opportunity, goodwill or reputation; or</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.7.5 </span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>any indirect or consequential loss or damage.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>6. General provisions</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>We make changes to these Terms and Conditions from time to time. You should therefore check these Terms of Conditions whenever you return to our Website to see whether any changes have been made, as these will be binding on you.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>We may transfer our contract to someone else. We may transfer our rights and obligations under these Terms and Conditions to another organisation.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You need our consent to transfer your rights to someone else.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.4.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Nobody else has any rights under our contract. This contract is between you and us. No other person shall have any rights to enforce any of its terms.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.5.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>If a court finds part of this contract illegal, the rest will continue in force. Each of the paragraphs of these Terms and Conditions operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.6.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Even if we delay in enforcing these Terms and Conditions, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these Terms and Conditions, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '12pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>If you are an individual consumer</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.7.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Which laws apply to our contract and where you may bring legal proceedings. These Terms and Conditions are governed by South African law and you can bring legal proceedings in the South African courts. </span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '12pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>If you are a business customer</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.8.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>If you are a business user these Terms of Use (and any non-contractual obligations arising out of or in connection with them) are governed by the law of South Africa and the courts of South Africa have exclusive jurisdiction to determine any dispute arising out of or in connection with them (including without limitation in relation to any non-contractual obligations).</span>
                  </p>
                  <p>
                    <span style={{AwImport: 'ignore'}}>&nbsp;</span>
                  </p>
                  <p>
                    <span style={{AwImport: 'ignore'}}>&nbsp;</span>
                  </p>
                  <p style={{marginBottom: '14pt', textAlign: 'left', lineHeight: 'normal', fontSize: '18pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Terms &amp; Conditions</span>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>for Agents</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>These Terms and Conditions will apply to the facilitation services that Askjojo will make available to you, and are in addition to the website terms of use that apply from time to time, to your use of the Website (as defined below).</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>1.Definitions and Interpretation</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>1.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>In these Terms and Conditions, the following words and expressions have the following meanings:</span>
                  </p>
                  <table cellSpacing={2} cellPadding={0} style={{marginBottom: '0pt', borderSpacing: '1.5pt'}}>
                    <tbody><tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“Request”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>a request from a Prospective Customer as referred to in clause 2.3 below;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“Credits”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>the credits purchased by an Agent as set out in Schedule 1, which entitles the Agent to access relevant Requests;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“Customer”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>a Prospective Customer who chooses an Agent to provide services to them;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“Customer Contract”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>the contract between the Agent and the Customer for the provision of the Services;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“Prime Agent”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>an optional subscription service which Agents can register for, which boosts their profile and improves visibility of their services to Prospective Customers on the Website;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“Fees”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>the fees payable by you as set out in Schedule 1;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>the “Facilitation Services”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>the provision by Askjojo, of a Prospective Customer’s contact details to an Agent, in exchange for Credits, so that they become a Contact, as set out in clause 2.4;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“Contact”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>a Prospective Customer, whose contact details have been provided to an Agent, as part of the Facilitation Services;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“Prospective Customers”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>businesses and consumers who use the Website to find an Agent who can provide the professional services they require;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>the “Services”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>the professional services provided by an Agent to a Customer;</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“us” / “we” / “Askjojo”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                            <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#111637'}}>Askjojo Pty Ltd, registered address </span>
                            <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Cresta Junction, Suite 215 Block B, Second Floor, Cnr Beyers Naude Drive and Judges Avenue, Cresta, Johannesburg, 2194.</span>
                            <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#111637'}}> (registered in South Africa, registration number </span>
                            <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>2023/790711/07</span>
                            <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#111637'}}>);</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>the “Website”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>means</span>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>&nbsp;</span>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>www.askjojo.co.za </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>“you / the Agent”</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>means the person offering their professional services to Prospective Customers;</span>
                          </p>
                        </td>
                      </tr>
                    </tbody></table>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>1.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>all headings are for ease of reference only and will not affect the construction or interpretation of these Terms and Conditions;</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>1.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>references to a “person” include any individual, body corporate, association, partnership, firm, trust, organisation, joint venture, government, local or municipal authority, governmental or supra-governmental agency or department, state or agency of state or any other entity (in each case whether or not having separate legal personality);</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>1.4.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>references to any legislation or legislative provision will include any subordinate legislation made under it and will be construed as references to such legislation, legislative provision and/or subordinate legislation as modified, amended, extended, consolidated, re-enacted and/or replaced and in force from time to time;</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>1.5.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>any words following the words “include”, “includes”, “including”, “in particular” or any similar words or expressions will be construed without limitation and accordingly will not limit the meaning of the words preceding them and the ejusdem generis rule will not apply;</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>1.6.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>references to “in writing” or “written” include e-mail.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>2.Askjojo’s contractual model &amp; a description of the facilitation service</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Upon acceptance of these Terms and Conditions by you, and in exchange for the Fees, Askjojo will provide contact details to you from time to time as more particularly described below.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Askjojo operates the Website which has been established to facilitate the provision of potential customers’ contact details to Agents for the provision of a real estate related services.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Prospective Customers will be able to search the Website to find potential Agents to meet the Prospective Customer’s requirements. We call Prospective Customer requests “Requests”, and each Request will provide details about the Prospective Customer’s requirements.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.4.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>By accepting these Terms and Conditions, you agree that you will need to register as an “Agent” on the Website, which will mean that you will have the right to access relevant Requests in exchange for Credits. If you redeem Credits in response to a particular Request, Askjojo will share with you the contact details which have been provided by Prospective Customers, who will then be known as Contacts, so that you can get in touch with them. The Prospective Customer will be entitled to review any responses they receive from you and other Agents, and may or may not decide to enter into a separate contract with you or another Agent. Where you are engaged by a Prospective Customer, those persons will become your Customer. For the avoidance of doubt, a Customer is a customer of the Agent, and not a customer of Askjojo.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.5.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Upon completion of an Agent’s registration on the Website, Askjojo shall provide a link from the Website to the Agent’s own website, and shall include a facility for Customers to review an Agent’s performance which shall be available on the Website for Prospective Customers to see. You acknowledge and agree that any reviews left are the Customers’ own opinion, and that Askjojo is not liable to you in any way whatsoever for the content of such reviews. You will ensure that any website that is linked to your profile on the Website complies with all applicable laws, guidance and regulations.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.6.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Agents who subscribe to the Prime Agent service will be liable for the additional fees set out in Schedule 1</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>2.7.</span>
                    <span style={{fontFamily: '"jost"', fontWeight: 500, fontSize: '12pt', color: '#111637'}}>Important:</span>
                    <br />
                    <span style={{fontFamily: '"jost"', fontWeight: 500, fontSize: '12pt', color: '#111637'}}>Askjojo acts only as a facilitator of the Contacts referred to above, and the provision of any services by an Agent (including the Services provided by you) to a Customer will be subject to an entirely separate legal contract. If there are any issues with performance of those Services by you, the Customer will have legal rights of redress against you, as the Agent, directly.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>3.Quality of the Facilitation Services</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>3.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>The Facilitation Services will be provided by Askjojo using reasonable care and skill . Time will not be of the essence where we have stated on the Website that any Contacts will be provided within a prescribed period of time.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>3.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>In the case of Askjojo’s performance of the Facilitation Services, unless expressly stated otherwise in these Terms and Conditions, all warranties, conditions and other terms implied by law (whether by statute, common law or otherwise) are excluded from these Terms and Conditions</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>3.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Unlike other providers in the market, the Facilitation Services do not make any guarantees that you will be successful in being hired by a Prospective Customer for services, and our role is only to give you the opportunity to make contact with a Prospective Customer. If you are not selected or your services not used by the Prospective Customer who has issued a Request on our Website, you will not be entitled to any Credit refund from us. Please see Section 6 below for further information regarding our policy on Credit refunds.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>4.The Agent’s obligations</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>4.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You warrant to Askjojo that the Services that you provide to your Customers will be performed by you and anyone acting on your behalf with due care and diligence, with integrity and to such high standard of quality as it is reasonable for the Customer to expect in all the circumstances, and shall comply at all times with all laws, guidance and regulations that apply to your provision of the Services to your Customers (including, without limitation, those relating to data privacy, consumer law, and health &amp; safety).</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>4.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You will at all times: (a) cooperate with us in all matters relating to the Facilitation Services; (b) provide, in a timely manner, such information as we may reasonably require, and ensure that it is accurate and complete in all material respects; (c) comply at all times with our Website Terms of Use; (d) comply with all policies and requirements as specified on the Website, as updated by us from time to time; (e) comply with any vetting requirements that we may impose on you from time to time; (f) ensure that you make it clear to Customers that any Services to be provided are provided to them by you and not by Askjojo.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>4.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You will comply with all applicable laws, statutes, regulations and codes relating to anti-bribery and anti-corruption including but not limited to the Prevention and Combating of Corrupt Activities Act, 2004 and Prevention of Organised Crime Act, 1998.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>5.Purchasing &amp; Using Credits</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>To have access to Contacts, you will be required to purchase Credits from us. The cost of Credits are set out in Schedule 1. VAT (or local equivalent) shall be payable in addition to the Credit costs specified in Schedule 1.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>All Credits shall be payable in accordance with Schedule 1.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>5.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You will be able to redeem Credits you have purchased in exchange for Contacts on the Website, and the Website will tell you how many Credits you will need to use in exchange for each individual Contact.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>6.Refunding Credits</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>All Credits purchased are non-refundable except as provided for in paragraph 6.2 below. We will not, for example, offer any return of Credits if the Contact(s) we provide you with are correct, but where the Prospective Customer chooses not to respond to your introduction, or if you do not secure the job with the Prospective Customer. There may be cases where a Prospective Customer decides not to choose any of the Agents we introduce them to, and this is entirely at the discretion of the Prospective Customer. In these instances, no refund of Credits would apply.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>You will only be entitled to a refund of Credits if: (a) A phone number provided to you is invalid, for example if it says “calls are not being connected to this number”; or (b) an email address provided to you by us isn’t functioning and you receive a bounce-back when using the email address; or (c) we can verify that the contact details provided are not for the person submitting the request.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>6.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Where you are entitled to receive a refund of Credits in accordance with clause 6.2 above, you acknowledge that you will not receive a monetary refund for those Credits, and we will instead reinstate those refundable Credits onto your Askjojo account.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>7.Intellectual Property Rights</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>The property and any copyright design rights or other intellectual property rights in any material, data or other information provided to you by Askjojo, including all information and content on the Website, (subject to any such rights of any third party) belong (or continue to belong) to Askjojo.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>8.Data Protection</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Please see our</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#2d7af1'}}>Privacy Policy</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>and</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#2d7af1'}}>Cookie Policy</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>&nbsp;</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>for more information on how your personal information will be used.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>9.Liability</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>9.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>As provided for in Clause 2 above, you acknowledge that you remain contractually liable to Customers for the provision of the Services, but you acknowledge that Askjojo may suffer reputational damage if you do not perform your contractual obligations under Customer Contracts properly. You hereby agree to indemnify Askjojo in full against all losses (including all direct, indirect and consequential losses), liabilities, costs, damages and expenses that we do or will incur or suffer, all claims or proceedings made, brought or threatened against Askjojo by any person (including, without limitation Customers) and all losses, liabilities, costs (on a full indemnity basis), damages and expenses we do or will incur or suffer as a result of defending or settling any such actual or threatened claim or proceeding, in each case arising out of or in connection with your breach of your obligations set out in Clause 2 above.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>9.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Subject to Clause 9.4 below, Askjojo’s maximum aggregate liability under these Terms and Conditions will be limited to the Value of Credits purchased in the preceding twelve month period.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>9.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Subject to Clause 9.4 below, Askjojo will have no liability to you for any: (a) loss of profit, loss of sales or business (in each case whether direct, indirect or consequential); (b) loss of or damage to goodwill, loss of reputation or loss of opportunity (in each case whether direct, indirect or consequential); (c) any liability you may owe to any third party, including, but not limited to, a Customer (in each case whether direct, indirect or consequential); (d) loss of use or value of any data or software (in each case whether direct, indirect or consequential); (e) indirect or consequential loss.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>9.4.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Nothing in these Terms and Conditions will operate to exclude or restrict any liability of either you or us: (a) that cannot be excluded or restricted in these Terms and Conditions in respect of death or personal injury resulting from negligence; (b) for its fraud or fraudulent misrepresentation or fraud or fraudulent misrepresentation by a person for whom it is vicariously liable; or (c) for any matter for which it is not permitted by law to exclude or limit, or to attempt to exclude or limit, its liability.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>10.Information &amp; Confidentiality</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>10.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Any information provided by Askjojo or concerning Askjojo’s affairs shall be kept confidential by you and all information provided by you which is so designated by you shall be kept confidential by Askjojo; but the foregoing shall not apply to any documents or other materials, data or other information which enter the public domain (other than by breach of this Clause 10.1, or where any disclosure is required by law or regulatory authority.)</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>10.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Neither party shall use the other’s name, trademarks, service marks or logos for any promotional or publicity purposes without the express written consent of the other.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>11.Termination</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>11.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Without affecting any other right or remedy available to us, Askjojo may withdraw your registration as an Agent on the Website with immediate effect by giving you notice if: (a) you fail to pay any sums due to us during the Term; (b) if you fail to comply with your obligations in Clause 4 relating to your performance of the Services Contract with your Customers; (c) if you receive a disproportionate number of complaints and/or bad reviews on the Website from Customers and/or Prospective Customers regarding your Services and/or dealings with customers more generally; (d) if, in our absolute discretion, we consider your registration as an Agent on the Website to be detrimental or potentially detrimental to the reputation of Askjojo; (e) you are subject to an investigation by a competent regulatory authority, including without limitation, the Information Commissioner, the Competition Commission or Property Practitioners Regulatory Authority.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>11.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Without affecting any other right or remedy available to either of us, either you or we may withdraw your registration as an Agent on the Website with immediate effect by giving written notice to the other if: (a) the other party commits a material breach of any term of these Terms and Conditions; (b) the other party takes any step or action in connection with its entering administration, provisional liquidation or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), being wound up, having a receiver appointed to any of its assets or ceasing to carry on its business; (c) the other party suspends, or threatens to suspend or cease or threatens to cease to carry on all or a substantial part of its business or (d) the other party’s financial position deteriorates to such an extent that it in the terminating party’s opinion the other party’s capability to adequately fulfil its obligations under these Terms and Conditions has been placed in jeopardy.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>11.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>In the event that you cease to provide the Services and / or you do not wish to continue to be listed on the Website, you shall notify us immediately so that we may withdraw your registration as an Agent on the Website so that Prospective Customers are not provided with your details. For the avoidance of doubt, as set out in clause 6, no refunds of Credits shall be given upon such withdrawal.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>12.General provisions</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>12.1.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>These Terms and Conditions are personal to you and you will not assign or transfer or subcontract any of your rights or obligations without our prior written consent.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>12.2.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Any notice required or permitted to be given by either party to the other under these Terms and Conditions are to be in writing addressed to the other party at its trading address or such other address as may at the relevant time have been notified to the other party.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>12.3.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>Nothing in these Terms and Conditions is intended to, or shall be deemed to, establish any partnership or joint venture between the parties, constitute one party the agent of the other party, or authorise a party to make or enter into any commitments for or on behalf of the other party. The parties acknowledge and agree that each of them is an independent contractor, and nothing in these Terms and Conditions shall render either party (nor any staff of a party) an employee of the other.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>12.4.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>If any provision of these Terms and Conditions is held by any competent authority to be invalid or unenforceable in whole or in part the validity of the remainder of the provisions in question shall not be affected.</span>
                  </p>
                  <p style={{marginBottom: '14pt', lineHeight: '21pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, fontSize: '12pt', color: '#111637'}}>12.5.</span>
                    <span style={{fontFamily: '"jost"', fontSize: '12pt', color: '#3b416d'}}>These Terms and Conditions will be governed South African law and shall be subject to the exclusive jurisdiction of the South African courts.</span>
                  </p>
                  <p style={{marginTop: '14pt', marginBottom: '14pt', lineHeight: 'normal', fontSize: '13.5pt', backgroundColor: '#ffffff'}}>
                    <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Schedule 1</span>
                  </p>
                  <table cellSpacing={2} cellPadding={0} style={{marginBottom: '0pt', borderSpacing: '1.5pt'}}>
                    <tbody><tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Credit costs:</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>R11.50</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Payment for Credits:</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>Credits will be purchased in advance on the Website and credited to the Agent’s account.</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', fontWeight: 800, color: '#111637'}}>Prime Agent subscription cost:</span>
                          </p>
                        </td>
                        <td style={{padding: '0.75pt', verticalAlign: 'top', backgroundColor: '#f3f3f6'}}>
                          <p style={{marginBottom: '0pt', fontSize: '12pt'}}>
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>Monthly fee – R199.00 - R499.00 ex VAT</span>
                            <br />
                            <span style={{fontFamily: '"jost"', color: '#111637'}}>Annual Fee – R2270.00 - R4950.00 ex VAT</span>
                          </p>
                        </td>
                      </tr>
                    </tbody></table>
                  <p>
                    <span style={{AwImport: 'ignore'}}>&nbsp;</span>
                  </p>
                </div>
              </div>
              <div className="col-md-12 text-center mg-bottom-20">
                <div className="col-md-12">
                  <lord-icon src="https://cdn.lordicon.com/vxsnbfop.json" trigger="loop" colors="primary:#e4e4e4,secondary:#9cc2f4,tertiary:#3080e8,quaternary:#b4b4b4,quinary:#66a1ee,senary:#1663c7" style={{width: '200px', height: '200px'}}>
                  </lord-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Currency Converter widget by FreeCurrencyRates.com */}
     
    </section>
  )
}

export default TermsPage
