import React from 'react'
import {Navbar, Nav} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import logo from '../assets/images/logo-jojo.svg'

const Navigation = () => {
  return (
    // <Navbar fixed="top" className="custom-navbar top-nav-collapse">
    //   <Container>
    //     <Navbar.Brand className="smoothScroll">
    //       <img src={logo} alt="Logo" />
    //     </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //     <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
    //       <Nav>
    //         <Nav.Link href="/rent" className="smoothScroll">
    //           Rent
    //         </Nav.Link>
    //         <Nav.Link href="/buy" className="smoothScroll">
    //           Buy
    //         </Nav.Link>
    //         <Nav.Link href="/sell" className="smoothScroll">
    //           Sell
    //         </Nav.Link>
    //         <Nav.Link href="/agent" className="smoothScroll">
    //           Agent Sign Up
    //         </Nav.Link>
    //       </Nav>
    //       <div className="manje desktop-only">
    //         <div style={{width: '125px', float: 'left', margin: '-7px 62px 0'}} className="apo">
    //           <img src={logo} alt="Logo" />
    //         </div>
    //       </div>
    //       <Nav>
    //         <Nav.Link href="/credits" className="smoothScroll">
    //           Buy jojo Credits
    //         </Nav.Link>
    //         <Nav.Link href="/help" className="smoothScroll">
    //           Help
    //         </Nav.Link>
    //         <Nav.Link href="/contact" className="smoothScroll">
    //           Contact Us
    //         </Nav.Link>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>

    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="container-fluid d-flex align-items-center justify-content-between no-pad">
        <nav className="navbar navbar-expand-lg navbar-light bg-light col-12">
          <div className="container-fluid">
            <a href="/" className="navbar-brand mobile-only">
              <img src={logo} alt="Logo" className="mini-logo" />
            </a>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav go-mid">
                <a href="/rent" className="nav-item nav-link">
                  Rent
                </a>
                <a href="/buy" className="nav-item nav-link">
                  Buy
                </a>
                <a href="/sell" className="nav-item nav-link">
                  Sell
                </a>
                <a href="/agent" className="nav-item nav-link">
                  Agent Sign Up
                </a>
                <div className="manje desktop-only">
                  <div style={{width: '125px', float: 'left', margin: '2px 62px 0'}} className="apo">
                    <a href="/">
                      <img src={logo} alt="Logo" />
                    </a>
                  </div>
                </div>
                <a href="/credits" className="nav-item nav-link">
                  Buy Jojo Credits
                </a>
                <a href="/about" className="nav-item nav-link">
                  About Us
                </a>
                {/* <a href="/help" className="nav-item nav-link">
                  Help
                </a> */}
                <a href="/contact" className="nav-item nav-link">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Navigation
