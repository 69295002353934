import {useConfig} from 'contexts/config'

const useMutation = () => {
  const {token} = useConfig()
  const handleSubmit = async ({values, url, method, onSuccess}) => {
    try {
      const rawResult = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
      const result = await rawResult.json()

      console.log('result======', result)
      if (onSuccess && !result?.error) {
        onSuccess()
      }
      return result
    } catch (error) {
      console.log('error useMutation', error)
    }
  }

  return {mutate: handleSubmit}
}

export default useMutation
