import React from 'react'
import {Button} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import wiredBlue from 'assets/images/wired-blue.gif'

const copy = {
  amenitiesHeading: '7. What type of amenities must the property have?',
  amenitiesHelpText: `What luxuries and extras are you looking for?`,
  next: 'Next',
  back: 'Back',
}

const StepAmenities = ({handleChange, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">{copy.amenitiesHeading}</h3>
      <div className="form-group">
        <div className="line">
          <input type="checkbox" className="my-checkbox" name="amenities" value="any" onChange={handleChange} />
          Any, I am not really fussy
        </div>
        <div className="line">
          <input
            type="checkbox"
            className="my-checkbox"
            name="amenities"
            value="It must have a pool"
            onChange={handleChange}
          />
          It must have a pool
        </div>
        <div className="line">
          <input
            type="checkbox"
            className="my-checkbox"
            name="amenities"
            value="It must be pet friendly"
            onChange={handleChange}
          />
          It must be pet friendly
        </div>
        <div className="line">
          <input
            type="checkbox"
            className="my-checkbox"
            name="amenities"
            value="It must have a patio/balcony"
            onChange={handleChange}
          />
          It must have a patio/balcony
        </div>
        <div className="line">
          <input
            type="checkbox"
            className="my-checkbox"
            name="amenities"
            value="It must have a garden"
            onChange={handleChange}
          />
          It must have a garden
        </div>
        <div className="line">
          <input
            type="checkbox"
            className="my-checkbox"
            name="amenities"
            value="It must be furnished"
            onChange={handleChange}
          />
          It must be furnished
        </div>
      </div>
      <div className="form-group go-left col-md-12" style={{marginTop: '17px'}}>
        <input type="text" name="amenitiesOther" onChange={handleChange} placeholder="Type other answers here" />
      </div>

      <div className="explaina text-center col-md-8 offset-md-2">{copy.amenitiesHelpText}</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredBlue} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps">
            <div className="rate" style={{width: '60%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back" onClick={previous}>
        {copy.back}
      </Button>
      <Button variant="primary" className="next" onClick={next} disabled={values?.amenities?.length < 1}>
        {copy.next}
      </Button>
    </div>
  )
}

export default StepAmenities
