import React from 'react'
import {Form, Button} from 'react-bootstrap'
import {ErrorMessage} from 'formik'

const copy = {
  regDetailsHeading: 'Registration Detail',
  aboutYourself: 'Tell us a bit about yourself',
  next: 'Next',
  back: 'Back',
}

const StepRegistrationDetails = ({handleChange, handleBlur, values, errors, touched, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">{copy.regDetailsHeading}</h3>
      <Form.Group>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Name"
          isValid={touched.name && !errors.name}
          isInvalid={touched.name && errors.name}
        />
        <ErrorMessage name="name" component="div" className="invalid-feedback error" />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          name="surname"
          value={values.surname}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Surname"
          isValid={touched.surname && !errors.surname}
          isInvalid={touched.surname && errors.surname}
        />
        <ErrorMessage name="surname" component="div" className="invalid-feedback error" />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Email"
          isValid={touched.email && !errors.email}
          isInvalid={touched.email && errors.email}
        />
        <ErrorMessage name="email" component="div" className="invalid-feedback error" />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          name="cellphone"
          value={values.cellphone}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Cell Number"
          isValid={touched.cellphone && !errors.cellphone}
          isInvalid={touched.cellphone && errors.cellphone}
        />
        <ErrorMessage name="cellphone" component="div" className="invalid-feedback error" />
      </Form.Group>
      <div className="explaina text-center col-md-8 offset-md-2">{copy.aboutYourself}</div>
      <Button variant="" className="back" onClick={previous}>
        {copy.back}
      </Button>
      <Button type="submit" variant="primary" className="next" disabled={Object.keys(touched)?.length <= 3}>
        {copy.next}
      </Button>
    </div>
  )
}

export default StepRegistrationDetails
