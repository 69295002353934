import React from 'react'
import Image from 'react-bootstrap/Image'
import wiredRed from 'assets/images/wired-red.gif'
import {Button} from 'react-bootstrap'

const StepPreQualifiedAmount = ({handleChange, handleBlur, values, previous, next}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">
        11. How much have you been pre-qualified for if you are buying with bank finance
      </h3>
      <div className="form-group">
        <input
          type="text"
          name="preQualifiedAmount"
          value={values.preQualifiedAmount}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Type answer here"
        />
      </div>

      <div className="explaina-red text-center col-md-8 offset-md-2">Help us look at your affordability</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredRed} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps-red">
            <div className="rate-red" style={{width: '100%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back-red" onClick={previous}>
        Back
      </Button>
      <Button type="submit" variant="primary" className="next-red" onClick={next}>
        Next
      </Button>
    </div>
  )
}

export default StepPreQualifiedAmount
