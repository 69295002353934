import React, {useState, useEffect} from 'react'
import {Col, Container, Form, Row, Button} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {Formik, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useConfig} from 'contexts/config'
import useMutation from 'hooks/useMutation'
import SuggestionsList from 'components/SuggestionsList'
import debounce from 'lodash/debounce'

const copy = {
  agentRegHeading: 'Become an agent',
  agentRegDescText: `Registration Detail`,
  agentRegHelpText: `Tell us a bit about yourself`,
  next: 'Next',
}

const initialValues = {
  name: '',
  surname: '',
  email: '',
  cellphone: '',
  agencyName: '',
  location: '',
}

const AgentRegistrationSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().email().required(),
  cellphone: Yup.string().required(),
  agencyName: Yup.string().required('Agency name is required'),
  location: Yup.string().required(),
})

const AgentRegistrationForm = () => {
  const navigate = useNavigate()
  const {ajApiUrl} = useConfig()
  const mutation = useMutation()
  const [suggestions, setSuggestions] = useState([])
  const [query, setQuery] = useState('')

  const fetchSuggestionsDebounced = debounce((inputValue) => {
    const trimmedInput = inputValue.trim()

    fetch(`${ajApiUrl}/getAutocompleteLocations?suburbPhrase=${trimmedInput}`)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setSuggestions(data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, 300)

  const handleInputChange = (event, setFieldValue) => {
    const inputValue = event.target.value
    setQuery(inputValue)
    setFieldValue('location', inputValue)
    fetchSuggestionsDebounced(inputValue)
  }

  useEffect(() => {
    return () => {
      fetchSuggestionsDebounced.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col></Col>
          <Col md={6}>
            <h1 className="heading">{copy.agentRegHeading}</h1>
            <div className="col-md-12 white-bg big-pad relative">
              <h3 className="prod-title text-center bottom-30">{copy.agentRegDescText}</h3>
              <Formik
                initialValues={initialValues}
                validationSchema={AgentRegistrationSchema}
                onSubmit={(values, actions) => {
                  mutation.mutate({
                    values,
                    url: `${ajApiUrl}/register`,
                    method: 'POST',
                    onSuccess: () => navigate('/thanks'),
                  })

                  actions.setSubmitting(false)
                }}
              >
                {({values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors}) => (
                  <form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.name && !errors.name}
                        isInvalid={touched.name && errors.name}
                        placeholder="Name"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="name" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="surname"
                        value={values.surname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.surname && !errors.surname}
                        isInvalid={touched.surname && errors.surname}
                        placeholder="Surname"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="surname" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && errors.email}
                        placeholder="Email"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="email" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="cellphone"
                        value={values.cellphone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.cellphone && !errors.cellphone}
                        isInvalid={touched.cellphone && errors.cellphone}
                        placeholder="Cell Number"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="cellphone" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="agencyName"
                        value={values.agencyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.agencyName && !errors.agencyName}
                        isInvalid={touched.agencyName && errors.agencyName}
                        placeholder="Agency name"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="agencyName" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="location"
                        value={query || values.location}
                        placeholder="Type location name"
                        onChange={(event) => handleInputChange(event, setFieldValue)}
                        onBlur={handleBlur}
                        isValid={touched.location && !errors.location}
                        isInvalid={touched.location && errors.location}
                      />
                      <SuggestionsList
                        suggestions={suggestions}
                        setFieldValue={setFieldValue}
                        setQuery={setQuery}
                        setSuggestions={setSuggestions}
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="location" />
                    </Form.Group>
                    <div className="explaina text-center col-md-8 offset-md-2">{copy.agentRegHelpText}</div>
                    <Button type="submit" variant="primary" className="next">
                      {copy.next}
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </section>
  )
}

export default AgentRegistrationForm
