import React from 'react'
import {Button} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import wiredBlue from 'assets/images/wired-blue.gif'

const copy = {
  paymentAmountHeading: '10. How much would you like to pay monthly?',
  paymentAmountHelpText: `What do you expect excluding rates?`,
  next: 'Next',
  back: 'Back',
}

const StepPaymentAmount = ({handleChange, handleBlur, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">{copy.paymentAmountHeading}</h3>
      <div className="form-group">
        <input
          type="text"
          name="paymentAmount"
          value={values.paymentAmount}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Type answer here"
        />
      </div>

      <div className="explaina text-center col-md-8 offset-md-2">{copy.paymentAmountHelpText}</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredBlue} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps">
            <div className="rate" style={{width: '90%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back" onClick={previous}>
        {copy.back}
      </Button>
      <Button variant="primary" className="next" onClick={next}>
        {copy.next}
      </Button>
    </div>
  )
}

export default StepPaymentAmount
