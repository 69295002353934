import React from 'react'
import {Tab, Tabs, Button} from 'react-bootstrap'

const CategorySection = () => {
  return (
    <section className="category-section home-section" id="shop">
      <h2 className="top-title">What would you like to do?</h2>

      <div className="wrapper">
        <Tabs defaultActiveKey="rent">
          <Tab eventKey="rent" title="Rent">
          <div className="call-to-action">
              Whether you’re looking for a luxury family home, a simple apartment, or anything to call home, we’ll help
              you find it.
              <br />
              <br />
              <a href="rent">
                <Button type="button" className="hero-btn col-md-6 col-xs-12">
                  Get Started
                </Button>
              </a>
            </div>
          </Tab>
          <Tab eventKey="buy" title="Buy">
          <div className="call-to-action">
              Whether you are purchasing your dream home or just looking for a good investment property, we’ll happily
              assist you.
              <br />
              <br />
              <a href="buy">
                <Button type="button" className="hero-btn col-md-6 col-xs-12">
                  Get Started
                </Button>
              </a>
            </div>
          </Tab>
          <Tab eventKey="sell" title="Sell">
            <div className="call-to-action">
              Whichever way you want to sell your home, we can help you find, navigate, and then achieve a successful
              sale.
              <br />
              <br />
              <a href="sell">
                <Button type="button" className="hero-btn col-md-6 col-xs-12">
                  Get Started
                </Button>
              </a>
            </div>
          </Tab>
        </Tabs>
      </div>
    </section>
  )
}

export default CategorySection
