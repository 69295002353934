import React, { createContext, useEffect, useState, useMemo } from 'react';
import GlobalError from 'containers/pages/errors/GlobalError';
import { useLocation } from 'react-router-dom';

// Define the type for the error handler configuration
export const ErrorStatusContext = createContext({ setError: null });

// The top-level component that will wrap our app's core features
export const ErrorHandler = ({ children }) => {
  const [error, setError] = useState(null);

  // Make sure to "remove" this status code whenever the user
  // navigates to a new URL. If we didn't do that, then the user
  // would be "trapped" into error pages forever
  const location = useLocation();

  useEffect(() => {
    setError(undefined);
  }, [location]);

  // This is what the component will render. If it has an
  // errorStatusCode that matches an API error, it will only render
  // an error page. If there is no error status, then it will render
  // the children as normal
  const renderContent = () => {
    if (error && error.status > 400) {
      return <GlobalError status={error.status} />;
    }

    // ... more HTTP codes handled here

    return children;
  };

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(() => ({ setError }), [setError]);

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ErrorStatusContext.Provider value={contextPayload}>
      {renderContent()}
    </ErrorStatusContext.Provider>
  );
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useErrorHandler = () => React.useContext(ErrorStatusContext);
