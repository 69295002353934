import React, {useState, useEffect} from 'react'
import Image from 'react-bootstrap/Image'
import {Form, Button} from 'react-bootstrap'
import {ErrorMessage} from 'formik'
import {useConfig} from 'contexts/config'
import debounce from 'lodash/debounce'
import SuggestionsList from 'components/SuggestionsList'

import wiredBlue from 'assets/images/wired-blue.gif'

const copy = {
  locationHeading: '1. Where would you like to rent?',
  locationHelpText: `Let's start by choosing the suburb or area you would like to rent in.`,
  next: 'Next',
}

const StepWhereToRent = ({setFieldValue, handleBlur, values, next, previous, touched, errors}) => {
  const [query, setQuery] = useState(values.location)
  const [suggestions, setSuggestions] = useState([])
  const {ajApiUrl} = useConfig()

  const fetchSuggestionsDebounced = debounce((inputValue) => {
    const trimmedInput = inputValue.trim()

    fetch(`${ajApiUrl}/getAutocompleteLocations?suburbPhrase=${trimmedInput}`)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setSuggestions(data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, 300)

  const handleInputChange = (event) => {
    const inputValue = event.target.value
    setQuery(inputValue)
    setFieldValue('location', inputValue)
    fetchSuggestionsDebounced(inputValue)
  }

  useEffect(() => {
    return () => {
      fetchSuggestionsDebounced.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">{copy.locationHeading}</h3>
      <Form.Group>
        <Form.Control
          type="text"
          name="location"
          value={query}
          onChange={handleInputChange}
          onBlur={handleBlur}
          placeholder="Type location name"
          isValid={touched.location && !errors.location}
          isInvalid={touched.location && errors.location}
        />
        <SuggestionsList
          suggestions={suggestions}
          setFieldValue={setFieldValue}
          setQuery={setQuery}
          setSuggestions={setSuggestions}
        />
        <ErrorMessage name="location" component="div" className="invalid-feedback error" />
      </Form.Group>
      <div className="explaina text-center col-md-8 offset-md-2">{copy.locationHelpText}</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredBlue} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps">
            <div className="rate" style={{width: '2%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="primary" className="next" disabled={!values?.location} onClick={next}>
        {copy.next}
      </Button>
    </div>
  )
}

export default StepWhereToRent
