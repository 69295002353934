import React, {useState} from 'react'
import StepWizard from 'react-step-wizard'
import {Formik, Form} from 'formik'

const WizardStep = ({initialValues, onSubmit, validationSchema, children}) => {
  const [state, updateState] = useState({
    form: {},
    demo: false,
  })

  const {SW} = state

  const setInstance = (SW) =>
    updateState({
      ...state,
      SW,
    })

  const goToNextStep = (e) => {
    e.preventDefault()

    if (SW) {
      SW.nextStep()
    }
  }

  const goToPreviousStep = (e) => {
    e.preventDefault()

    if (SW) SW.previousStep()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        if (Object.keys(values).length) {
          onSubmit(values, actions)
        }
      }}
    >
      {(props) => {
        const childrenWithProps = React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              wizardActions: SW,
              ...props,
              next: goToNextStep,
              previous: goToPreviousStep,
            })
          }

          return child
        })

        return (
          <Form>
            <StepWizard instance={setInstance}>{childrenWithProps}</StepWizard>
          </Form>
        )
      }}
    </Formik>
  )
}

export default WizardStep
