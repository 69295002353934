import React from 'react'
import {Col, Container, Form, Row, Button} from 'react-bootstrap'
import {Formik, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useConfig} from 'contexts/config'
import useMutation from 'hooks/useMutation'

const copy = {
  findAgentHeading: 'Find An Agent',
  findAgentDescText: `Get Help selling`,
  findAgentHelpText: `Tell us a bit about your Property`,
  next: 'Next',
}

const initialValues = {
  name: '',
  surname: '',
  email: '',
  cellphone: '',
  location: '',
  propertyType: '',
  propertyDescription: '',
  role: 'lead',
}

const FindAgentSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().email().required(),
  cellphone: Yup.string().required(),
  location: Yup.string().required(),
  propertyType: Yup.string(),
  propertyDescription: Yup.string(),
  role: Yup.string(),
})

const FindAgentPage = () => {
  const navigate = useNavigate()
  const {ajApiUrl} = useConfig()
  const mutation = useMutation()

  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col></Col>
          <Col md={6}>
            <h1 className="heading">{copy.findAgentHeading}</h1>
            <div className="col-md-12 white-bg big-pad relative">
              <h3 className="prod-title text-center bottom-30">{copy.findAgentDescText}</h3>
              <Formik
                initialValues={initialValues}
                validationSchema={FindAgentSchema}
                onSubmit={(values, actions) => {
                  mutation.mutate({
                    values,
                    url: `${ajApiUrl}/register`,
                    method: 'POST',
                    onSuccess: () => navigate('/thanks'),
                  })

                  actions.setSubmitting(false)
                }}
              >
                {({values, handleChange, handleBlur, handleSubmit, touched, errors}) => (
                  <form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Name"
                        isValid={touched.name && !errors.name}
                        isInvalid={touched.name && errors.name}
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="name" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="surname"
                        value={values.surname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.surname && !errors.surname}
                        isInvalid={touched.surname && errors.surname}
                        placeholder="Surname"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="surname" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && errors.email}
                        placeholder="Email"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="email" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="cellphone"
                        value={values.cellphone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.cellphone && !errors.cellphone}
                        isInvalid={touched.cellphone && errors.cellphone}
                        placeholder="Cell Number"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="cellphone" />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        as="select"
                        name="location"
                        onChange={handleChange}
                        isValid={touched.location && !errors.location}
                        isInvalid={touched.location && errors.location}
                      >
                        <option>Select city</option>
                        <option value="johannesburg">Johanesburg</option>
                        <option value="pretoria">Pretoria</option>
                        <option value="cape-Town">Cape Town</option>
                        <option value="durban">Durban</option>
                        <option value="polokwane">Polokwane</option>
                        <option value="rustenburg">Rustenburg</option>
                      </Form.Control>
                      <ErrorMessage className="invalid-feedback error" component="div" name="location" />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control as="select" name="propertyType" onChange={handleChange}>
                        <option>Property type</option>
                        <option value="flat-apartment">Flat/Apartment</option>
                        <option value="townhouse">Townhouse</option>
                        <option value="house">House</option>
                        <option value="farm">Farm</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Industrial">Industrial</option>
                        <option value="vacant-land">Vacant Land</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <textarea
                        name="propertyDescription"
                        value={values.propertyDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        cols="30"
                        rows="10"
                        placeholder="Describe your property briefly"
                      />
                    </Form.Group>

                    <div className="explaina text-center col-md-8 offset-md-2">{copy.leadsHelpText}</div>
                    <Button type="submit" variant="primary" className="next">
                      {copy.next}
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </section>
  )
}

export default FindAgentPage
