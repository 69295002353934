import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import wiredBlue from '../../assets/images/wired-blue.gif'

const CompletePage = () => {
  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="heading">
              Complete
              <br />
            </h1>
            <div className="col-md-6 offset-md-3 wizard white-bg go-left big-pad relative">
              <h3 className="prod-title text-center bottom-30">Thank You!</h3>

              <div className="explaina text-center col-md-8 offset-md-2">
                Thats it, all done here
                <br />
                <br />
              </div>
              <div className="text-center col-md-8 offset-md-2">
                <a href="/">
                  <button type="button" className="hero-btn col-md-6 col-xs-12">
                    Home
                  </button>
                </a>
              </div>
              <div className="col-md-12 text-center mg-bottom-20">
                <div className="col-md-12">
                  <Image src={wiredBlue} style={{margin: '0 auto', width: '50%'}} alt="" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CompletePage
