import React from 'react';
import { Container, Button } from 'react-bootstrap';

const GlobalError = ({ status = 404 }) => {
  let text = '';
  switch (status) {
    case 401:
      text = 'UNAUTHORIZED.';
      break;
    case 403:
      text = 'FORBIDDEN. You do not have access to the requested resources.';
      break;
    case 404:
      text = 'NOT FOUND. Could not find the resource you are looking for.';
      break;
    default:
      text = 'Oops, Something went wrong.';
  }
  return (
    <Container>
      <h1>{text}</h1>
      <Button variant="outlined" href="https://askjojo.com">
        Contact us
      </Button>
    </Container>
  );
};

export default GlobalError;
