import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useConfig} from 'contexts/config'
import useMutation from 'hooks/useMutation'

import WizardStep from 'components/WizardStep'

import StepRegistrationDetails from './wizard/StepRegistrationDetails'
import StepAmenities from './wizard/StepAmenities'
import StepBathrooms from './wizard/StepBathrooms'
import StepBedrooms from './wizard/StepBedrooms'
import StepRentPlan from './wizard/StepRentPlan'
import StepGarages from './wizard/StepGarages'
import StepParking from './wizard/StepParking'
import StepPets from './wizard/StepPets'
import StepPaymentAmount from './wizard/StepPaymentAmount'
import StepIncome from './wizard/StepIncome'
import StepPropertyType from './wizard/StepPropertyType'
import StepWhereToRent from './wizard/StepWhereToRent'

const RentHomeWizardSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().email().required(),
  cellphone: Yup.string().required(),
  location: Yup.string().required(),
  propertyType: Yup.string(),
  propertyTypeOther: Yup.string(),
  bedrooms: Yup.string(),
  bedroomsOther: Yup.string(),
  bathrooms: Yup.string(),
  bathroomsOther: Yup.string(),
  garages: Yup.string(),
  garagesOther: Yup.string(),
  parking: Yup.string(),
  parkingOther: Yup.string(),
  amenities: Yup.array(),
  amenitiesOther: Yup.string(),
  pets: Yup.string(),
  petsOther: Yup.string(),
  rentPlan: Yup.string(),
  rentPlanOther: Yup.string(),
  paymentAmount: Yup.string(),
  income: Yup.string(),
})

const initialValues = {
  name: '',
  surname: '',
  email: '',
  cellphone: '',
  location: '',
  propertyType: '',
  propertyTypeOther: '',
  bedrooms: '',
  bedroomsOther: '',
  bathrooms: '',
  bathroomsOther: '',
  garages: '',
  garagesOther: '',
  parking: '',
  parkingOther: '',
  amenities: '',
  amenitiesOther: '',
  pets: '',
  petsOther: '',
  rentPlan: '',
  rentPlanOther: '',
  paymentAmount: '',
  income: '',
  leadType: 'rent',
}

const title = 'Rent a home'

const RentHomePage = () => {
  const navigate = useNavigate()
  const {ajApiUrl} = useConfig()
  const mutation = useMutation()

  const onSubmit = (values, actions) => {
    console.log('onSubmit Rent', values)
    mutation.mutate({
      values,
      url: `${ajApiUrl}/lead`,
      method: 'POST',
      onSuccess: () => navigate('/thanks'),
    })

    actions.setSubmitting(false)
  }

  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col md={{span: 6, offset: 3}}>
            <h1 className="heading">
              {title}
              <br />
            </h1>
            <WizardStep initialValues={initialValues} onSubmit={onSubmit} validationSchema={RentHomeWizardSchema}>
              <StepWhereToRent />
              <StepPropertyType />
              <StepBedrooms />
              <StepBathrooms />
              <StepGarages />
              <StepParking />
              <StepAmenities />
              <StepPets />
              <StepRentPlan />
              <StepPaymentAmount />
              <StepIncome />
              <StepRegistrationDetails />
            </WizardStep>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default RentHomePage
