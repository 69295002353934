import React from 'react'
import Image from 'react-bootstrap/Image'
import wiredRed from 'assets/images/wired-red.gif'
import {Button} from 'react-bootstrap'

const StepPayment = ({handleChange, handleBlur, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">8. Are you buying with cash or finance?</h3>
      <div className="form-group">
        <select name="payment" onChange={handleChange}>
          <option>Please select</option>
          <option value="cash">I am buying with cash</option>
          <option value="finance">I am buying with finance</option>
          <option value="part">Part cash part finance</option>
          <option>Other</option>
        </select>
      </div>
      {values?.payment === 'other' && (
        <div className="form-group">
          <input
            type="text"
            name="paymentOther"
            value={values.paymentOther}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Type other answer here"
          />
        </div>
      )}

      <div className="explaina-red text-center col-md-8 offset-md-2">How will you fund this purchase</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredRed} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps-red">
            <div className="rate-red" style={{width: '70%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back-red" onClick={previous}>
        Back
      </Button>
      <Button variant="primary" className="next-red" onClick={next}>
        Next
      </Button>
    </div>
  )
}

export default StepPayment
