import React from 'react'
import {ListGroup} from 'react-bootstrap'

const SuggestionsList = ({suggestions, setFieldValue, setQuery, setSuggestions}) => {
  const handleItemSelect = (value) => {
    setFieldValue('location', value)
    setQuery(value)
    setSuggestions([])
  }

  return (
    <ListGroup
      style={{
        marginLeft: '7%',
        width: '86%',
        boxShadow: suggestions.length > 0 ? '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' : 'none',
      }}
    >
      {suggestions?.map((option) => (
        <ListGroup.Item key={option._id} onClick={() => handleItemSelect(option.Text)} className="hover-effect">
          <div>
            <span className="bold">{option.Text}</span> in {option.Descriptor}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}

export default SuggestionsList
