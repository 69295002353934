import React, {useState, useEffect} from 'react'
import Image from 'react-bootstrap/Image'
import {ErrorMessage} from 'formik'
import wiredRed from 'assets/images/wired-red.gif'
import {Form, Button} from 'react-bootstrap'
import SuggestionsList from 'components/SuggestionsList'
import {useConfig} from 'contexts/config'
import debounce from 'lodash/debounce'

const StepWhereToBuy = ({setFieldValue, handleBlur, values, next, touched, errors}) => {
  const [query, setQuery] = useState(values.location)
  const [suggestions, setSuggestions] = useState([])
  const {ajApiUrl} = useConfig()

  const fetchSuggestionsDebounced = debounce((inputValue) => {
    const trimmedInput = inputValue.trim()

    fetch(`${ajApiUrl}/getAutocompleteLocations?suburbPhrase=${trimmedInput}`)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setSuggestions(data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, 300)

  const handleInputChange = (event) => {
    const inputValue = event.target.value
    setQuery(inputValue)
    setFieldValue('location', inputValue)
    fetchSuggestionsDebounced(inputValue)
  }

  useEffect(() => {
    return () => {
      fetchSuggestionsDebounced.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">1. Where would you like to buy?</h3>

      <Form.Group>
        <Form.Control
          type="text"
          name="location"
          value={query}
          onChange={handleInputChange}
          onBlur={handleBlur}
          placeholder="Type location name"
          isValid={touched.location && !errors.location}
          isInvalid={touched.location && errors.location}
        />
        <SuggestionsList
          suggestions={suggestions}
          setFieldValue={setFieldValue}
          setQuery={setQuery}
          setSuggestions={setSuggestions}
        />
        <ErrorMessage className="invalid-feedback error" component="div" name="location" />
      </Form.Group>
      <div className="explaina-red text-center col-md-8 offset-md-2">
        Let's start by choosing the suburb or area you would like to buy in.
      </div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredRed} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps-red">
            <div className="rate-red" style={{width: '2%'}}></div>
          </div>
        </div>
      </div>

      <Button variant="primary" className="next-red" onClick={next}>
        Next
      </Button>
    </div>
  )
}

export default StepWhereToBuy
