import React from 'react'
import {Col, Container, Row, Button} from 'react-bootstrap'
import {Formik} from 'formik'
import * as Yup from 'yup'

const initialValues = {
  email: '',
  cellphone: '',
  credits: '',
}

const BuyCreditsSchema = Yup.object().shape({
  email: Yup.string().email(),
  cellphone: Yup.string(),
  credits: Yup.string(),
})

const BuyCreditsPage = () => {
  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="heading">
              Buy Credits
              <br />
            </h1>
            <div className="col-md-6 offset-md-3 wizard white-bg go-left big-pad relative">
              <h3 className="prod-title text-center bottom-30">Payment Details</h3>

              <Formik
                initialValues={initialValues}
                validationSchema={BuyCreditsSchema}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2))
                    actions.setSubmitting(false)
                  }, 1000)
                }}
              >
                {({values, handleChange, handleBlur, handleSubmit}) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Email"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="cellphone"
                        value={values.cellphone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Cell Number"
                      />
                    </div>

                    <div className="form-group">
                      <select name="credits" onChange={handleChange}>
                        <option>Select Credits</option>
                        <option value="200">200 Credits @ R5,000.00</option>
                        <option value="300">300 Credits @ R7,000.00</option>
                        <option value="400">400 Credits @ R9,000.00</option>
                        <option value="500">500 Credits @ R11,000.00</option>
                      </select>
                    </div>

                    <Button type="submit" className="next">
                      Pay
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BuyCreditsPage
