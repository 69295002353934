import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <p>Copyright askjojo.co.za 2023</p>
        <p><a href='/terms'>Terms & Conditions</a> <a href='/leads'>Lead</a></p>
      </div>
    </footer>
  )
}

export default Footer
