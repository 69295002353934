import React from 'react'
import {Container, Row, Form, Col, Button} from 'react-bootstrap'
import {Formik, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useConfig} from 'contexts/config'
import useMutation from 'hooks/useMutation'

const initialValues = {
  name: '',
  email: '',
  message: '',
}

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  message: Yup.string().required(),
})

const ContactUsPage = () => {
  const navigate = useNavigate()
  const {ajApiUrl} = useConfig()
  const mutation = useMutation()

  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="heading">
              Contact Us
              <br />
            </h1>
            <div className="wizard  col-md-6 offset-md-3 white-bg go-left big-pad relative">
              <h3 className="prod-title text-center bottom-30">
                23 Chester Rd Clearewater
                <br />
                011 236 8798
                <br />
                info@askjojo.co.za
              </h3>

              <Formik
                initialValues={initialValues}
                validationSchema={ContactUsSchema}
                onSubmit={(values, actions) => {
                  mutation.mutate({
                    values,
                    url: `${ajApiUrl}/enquiry`,
                    method: 'POST',
                    onSuccess: () => navigate('/thanks'),
                  })

                  actions.setSubmitting(false)
                }}
              >
                {({values, handleChange, handleBlur, handleSubmit, touched, errors}) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.name && !errors.name}
                        isInvalid={touched.name && errors.name}
                        placeholder="Name"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="name" />
                    </div>

                    <div className="form-group">
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && errors.email}
                        placeholder="Email"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="email" />
                    </div>

                    <div className="form-group">
                      <Form.Control
                        as="textarea"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.message && !errors.message}
                        isInvalid={touched.message && errors.message}
                        cols="30"
                        rows="10"
                        placeholder="Type message here"
                      />
                      <ErrorMessage className="invalid-feedback error" component="div" name="message" />
                    </div>
                    <div className="explaina text-center col-md-8 offset-md-2">Tell us a bit about yourself</div>

                    <Button type="submit" className="next">
                      Send
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ContactUsPage
