import React from 'react'
import Navigation from './Navigation'
import Body from './Body'
import Footer from './Footer'

const MainLayout = ({children}) => {
  return (
    <>
      <Navigation />
      <Body>{children}</Body>
      <Footer />
    </>
  )
}

export default MainLayout
