import React from 'react'
import Image from 'react-bootstrap/Image'
import wiredRed from 'assets/images/wired-red.gif'
import {Form, Button} from 'react-bootstrap'

const StepPropertyType = ({handleChange, handleBlur, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">2. What type of property are you looking for?</h3>

      <Form.Group>
        <Form.Control as="select" name="propertyType" onChange={handleChange}>
          <option>Please select</option>
          <option value="flat-apartment">Flat/Apartment</option>
          <option value="townhouse">Townhouse</option>
          <option value="house">House</option>
          <option value="farm">Farm</option>
          <option value="commercial">Commercial</option>
          <option value="industrial">Industrial</option>
          <option value="vacant-land">Vacant Land</option>
          <option value="other">Other</option>
        </Form.Control>
      </Form.Group>
      {values?.propertyType === 'other' && (
        <div className="form-group">
          <Form.Control
            type="text"
            name="propertyTypeOther"
            value={values.propertyTypeOther}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Type your answer"
          />
        </div>
      )}
      <div className="explaina-red text-center col-md-8 offset-md-2">
        What are your spatial needs? For family or just you and a friend?
      </div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredRed} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps-red">
            <div className="rate-red" style={{width: '10%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back-red" onClick={previous}>
        Back
      </Button>
      <Button variant="primary" className="next-red" onClick={next}>
        Next
      </Button>
    </div>
  )
}

export default StepPropertyType
