import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { decodeToken } from 'react-jwt';

const localConfig = {
  ajApiUrl: process.env.REACT_APP_API_URL + "/api",
  token: null,
  setToken: null,
  userName: null,
  env: "DEV"
};


const prodConfig = {
  ajApiUrl: process.env.REACT_APP_API_URL_PROD + "/api",
  token: null,
  setToken: null,
  userName: null,
  env: "PROD"
};

export const Config = createContext({} );

export const ConfigProvider = ({ children }) => {
  const config =
    process.env.REACT_APP_LOCAL === 'true'
      ? localConfig
      : prodConfig;

  console.log("config=====", config);

  const [token, setToken] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (token) {
      const decoded = decodeToken(token);
      console.log('decoded', decoded);
      setUserId(decoded.localAccountId);
      setUserName(decoded.name);
    }
  }, [token]);

  return (
    <Config.Provider
      value={{
        ...config,
        token,
        setToken,
        userName,
        userId,
      }}
    >
      {children}
    </Config.Provider>
  );
};

export const useConfig = () => React.useContext(Config)

ConfigProvider.propTypes = {
  children: PropTypes.node,
};
