import React from 'react'
import {Form, Button} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import wiredBlue from 'assets/images/wired-blue.gif'

const copy = {
  garagesHeading: '5. How many garages must the property have?',
  garagesHelpText: `If you have many cars, do let us know`,
  next: 'Next',
  back: 'Back',
}

const StepGarages = ({handleChange, handleBlur, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">{copy.garagesHeading}</h3>
      <div className="form-group">
        <Form.Control as="select" name="garages" onChange={handleChange}>
          <option>Please select</option>
          <option value="any">Any, I don't mind</option>
          <option value="1+">One or more</option>
          <option value="2+">Two or more</option>
          <option value="3+">Three or more</option>
          <option value="4+">Four or more</option>
          <option value="5+">Five or more</option>
          <option value="other">Other</option>
        </Form.Control>
      </div>

      {values?.garages === 'other' && (
        <div className="form-group">
          <Form.Control
            type="number"
            min="1"
            max="50"
            name="garagesOther"
            value={values.garagesOther}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Select your answer"
          />
        </div>
      )}

      <div className="explaina text-center col-md-8 offset-md-2">{copy.garagesHelpText}</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredBlue} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps">
            <div className="rate" style={{width: '40%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back" onClick={previous}>
        {copy.back}
      </Button>
      <Button variant="primary" className="next" onClick={next} disabled={!values?.garages}>
        {copy.next}
      </Button>
    </div>
  )
}

export default StepGarages
