import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'

const FindWithJojoSection = () => {
  return (
    <section id="work" style={{paddingTop: '60px'}}>
      <Container>
        <Row>
          <Col md={12}>
            <Col md={12}>
              <h1 className="heading" style={{color: '#000!important'}}>
                Find it with Jojo
                <br />
                <br />
              </h1>

              <Row>
                <Col md={4} className="go-left">
                  <div className="col-md-12 no-pad bottom-15px white-bg go-left">
                    <Image
                      src={require('../../assets/images/rent.svg').default}
                      style={{margin: '10% 10% 5%', width: '80%'}}
                      alt=""
                    />
                    <h3 className="prod-title text-center">Rent a home</h3>
                    <p>
                      A whole new way of leasing that takes the fear and uncertainty out of renting a home for you and
                      your loved ones.
                      <br />
                      <br />
                      <a href="/rent" className="hona">
                        Rent Now
                      </a>{' '}
                    </p>
                  </div>
                </Col>

                <Col md={4} className="go-left">
                  <div className="col-md-12 no-pad bottom-15px white-bg go-left">
                    <Image
                      src={require('../../assets/images/buy.svg').default}
                      style={{margin: '10% 10% 5%', width: '80%'}}
                      alt=""
                    />
                    <h3 className="prod-title text-center">Buy a home</h3>
                    <p>
                      A whole new way of leasing that takes the fear and uncertainty out of renting a home for you and
                      your loved ones.
                      <br />
                      <br />
                      <a href="/buy" className="hona">
                        Buy Now
                      </a>{' '}
                    </p>
                  </div>
                </Col>

                <Col md={4} className="go-left">
                  <div className="col-md-12 no-pad bottom-15px white-bg go-left">
                    <Image
                      src={require('../../assets/images/sell.svg').default}
                      style={{margin: '10% 10% 5%', width: '80%'}}
                      alt=""
                    />
                    <h3 className="prod-title text-center">Sell a home</h3>
                    <p>
                      A whole new way of leasing that takes the fear and uncertainty out of renting a home for you and
                      your loved ones.
                      <br />
                      <br />
                      <a href="/sell" className="hona">
                        Sell Now
                      </a>{' '}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        <Row style={{marginTop: '6%'}}>
          <Col md={12}>
            <Image
              src={require('../../assets/images/city.svg').default}
              style={{marginLeft: '10%', width: '80%'}}
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FindWithJojoSection
