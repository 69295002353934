import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useConfig} from 'contexts/config'
import useMutation from 'hooks/useMutation'

import WizardStep from 'components/WizardStep'

import StepRegistrationDetails from './wizard/StepRegistrationDetails'
import StepAmenities from './wizard/StepAmenities'
import StepBathrooms from './wizard/StepBathrooms'
import StepBedrooms from './wizard/StepBedrooms'
import StepBuyingPlan from './wizard/StepBuyingPlan'
import StepGarages from './wizard/StepGarages'
import StepParking from './wizard/StepParking'
import StepPayment from './wizard/StepPayment'
import StepPaymentAmount from './wizard/StepPaymentAmount'
import StepPreQualifiedAmount from './wizard/StepPreQualifiedAmount'
import StepPropertyType from './wizard/StepPropertyType'
import StepWhereToBuy from './wizard/StepWhereToBuy'

const BuyHomeWizardSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().email().required(),
  cellphone: Yup.string().required(),
  location: Yup.string().required(),
  propertyType: Yup.string(),
  propertyTypeOther: Yup.string(),
  bedrooms: Yup.string(),
  bedroomsOther: Yup.string(),
  bathrooms: Yup.string(),
  bathroomsOther: Yup.string(),
  garages: Yup.string(),
  garagesOther: Yup.string(),
  parking: Yup.string(),
  parkingOther: Yup.string(),
  amenities: Yup.array(),
  amenitiesOther: Yup.string(),
  payment: Yup.string(),
  paymentOther: Yup.string(),
  paymentAmount: Yup.string(),
  paymentAmountOther: Yup.string(),
  buyingPlan: Yup.string(),
  buyingPlanOther: Yup.string(),
  preQualifiedAmount: Yup.string(),
})

const initialValues = {
  name: '',
  surname: '',
  email: '',
  cellphone: '',
  location: '',
  propertyType: '',
  propertyTypeOther: '',
  bedrooms: '',
  bedroomsOther: '',
  bathrooms: '',
  bathroomsOther: '',
  garages: '',
  garagesOther: '',
  parking: '',
  parkingOther: '',
  amenities: '',
  amenitiesOther: '',
  payment: '',
  paymentOther: '',
  paymentAmount: '',
  paymentAmountOther: '',
  buyingPlan: '',
  buyingPlanOther: '',
  preQualifiedAmount: '',
  leadType: 'buy',
}

const title = 'Buy a home'

const BuyHomePage = () => {
  const navigate = useNavigate()
  const {ajApiUrl} = useConfig()
  const mutation = useMutation()

  const onSubmit = (values, actions) => {
    console.log('onSubmit Buy', values)
    mutation.mutate({
      values,
      url: `${ajApiUrl}/lead`,
      method: 'POST',
      onSuccess: () => navigate('/thanks'),
    })

    actions.setSubmitting(false)
  }

  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col md={{span: 6, offset: 3}}>
            <h1 className="heading">
              {title}
              <br />
            </h1>
            <WizardStep initialValues={initialValues} onSubmit={onSubmit} validationSchema={BuyHomeWizardSchema}>
              <StepWhereToBuy />
              <StepPropertyType />
              <StepBedrooms />
              <StepBathrooms />
              <StepGarages />
              <StepParking />
              <StepAmenities />
              <StepPayment />
              <StepBuyingPlan />
              <StepPaymentAmount />
              <StepPreQualifiedAmount />
              <StepRegistrationDetails />
            </WizardStep>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BuyHomePage
