import React from 'react'
import {Container, Row, Form, Col, Button} from 'react-bootstrap'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useConfig} from 'contexts/config'
import useMutation from 'hooks/useMutation'

const initialValues = {
  name: '',
  email: '',
  message: '',
}

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  message: Yup.string().required(),
})

const ViewLeadPage = () => {
  const navigate = useNavigate()
  const {ajApiUrl} = useConfig()
  const mutation = useMutation()

  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="heading">
              New Lead
              <br />
            </h1>
            <div className="wizard  col-md-6 offset-md-3 white-bg go-left big-pad relative line-height-15">
              Name: <span className='bold'>James k</span> <br />
              Email <span className='bold'>************</span><br />
            Phone Number <span className='bold'>***********</span><br />
            Lead type <span className='bold'>Buyer</span><br /><br />

            <span className='bold'>
            Johanesburg area - Northcliff<br />
            House<br />
            3+ Bedrooms<br />
            2+ Bathrooms<br />
            1+ Garages<br />
            0+ Parking<br />
            Pool, Gazebo, Electric fence<br />
            Buying Cash<br />
            Buying ASAP<br />
            R4,000,000.00<br />
            Buying Cash
            </span><br /><br /><br />
            <a href="#">
                <Button type="button" className="hero-btn col-md-12 col-xs-12">
                  Buy this lead (15 Credits)
                </Button>
              </a>
              <br />
             <p className='text-center bot-0'>60 Credits Available</p> 



             
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ViewLeadPage
