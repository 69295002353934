import React from 'react'
import Image from 'react-bootstrap/Image'
import wiredRed from 'assets/images/wired-red.gif'
import {Form, Button} from 'react-bootstrap'

const StepBedrooms = ({handleChange, handleBlur, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">3. How many bedrooms must the property have?</h3>

      <Form.Group>
        <Form.Control as="select" name="bedrooms" onChange={handleChange}>
          <option>Please select</option>
          <option value="any">Any, I don't mind</option>
          <option value="1+">One or more</option>
          <option value="2+">Two or more</option>
          <option value="3+">Three or more</option>
          <option value="4+">Four or more</option>
          <option value="5+">Five or more</option>
          <option value="other">Other</option>
        </Form.Control>
      </Form.Group>
      {values?.bedrooms === 'other' && (
        <div className="form-group">
          <Form.Control
            type="number"
            min="1"
            max="50"
            name="bedroomsOther"
            value={values.bedroomsOther}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Select your answer"
          />
        </div>
      )}
      <div className="explaina-red text-center col-md-8 offset-md-2">
        Either for sleeping or other needs, what's your need?
      </div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredRed} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps-red">
            <div className="rate-red" style={{width: '20%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back-red" onClick={previous}>
        Back
      </Button>
      <Button variant="primary" className="next-red" onClick={next} disabled={!values?.bedrooms}>
        Next
      </Button>
    </div>
  )
}

export default StepBedrooms
