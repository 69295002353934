import React from 'react'
import {Link} from 'react-router-dom'
import {Button, Col, Container, Row} from 'react-bootstrap'

const SellPage = () => {
  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="heading">Sell your home</h1>
            <div className="col-md-12 wizard white-bg go-left big-pad relative">
              <h3 className="prod-title text-center bottom-30">Sell Your Property With Confidence</h3>
              <p className="text-center">
                How you want to sell your property is entirely up to you. Askjojo makes it simpler for you to sell your
                property and move on.
              </p>
              <div className="col-md-12 no-pad go-left">
                <div className="col-md-5 go-left">
                  <img
                    src={require('assets/images/agent.png')}
                    alt=""
                    className="img-responsive"
                    style={{border: '1px solid #2d3dd7', marginBottom: '15px'}}
                  />
                </div>
                <div className="col-md-7 go-left top-60">
                  <p className="text-left">
                    <span className="bold">Option 1: Sell your property traditionally with an agent</span>
                    <br />
                    <br />
                    Working with a real estate agent gives you selling support at every step of the way, from
                    evaluation, prepping and listing your property to professional marketing that exposes your property
                    to the right buyers.
                    <br />
                    <br />
                    <span className="bold">Why should you sell traditionally with an agent?</span>
                    <ul className="why">
                      <li>
                        An agent will help you navigate a stressful process by giving you a dedicated guide from start
                        to finish
                      </li>
                      <li>An experienced agent will access their local market expertise</li>
                      <li>An agent will help you with reviewing and negotiating offers</li>
                      <li>An agent will help you with the correct valuation of your property</li>
                    </ul>
                  </p>
                  <p className="text-left">
                    <Link to="/find">
                      <Button type="button" className="hero-btn col-md-4 col-xs-12">
                        Find an agent
                      </Button>
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-md-12 go-left top-60 no-pad">
                <div className="col-md-5 go-left">
                  <img
                    src={require('assets/images/self.png')}
                    alt=""
                    className="img-responsive"
                    style={{border: '1px solid #2d3dd7', marginBottom: '15px'}}
                  />
                </div>
                <div className="col-md-7 go-left top-60">
                  <p className="text-left">
                    <span className="bold">Option 2: Sell your property yourself</span>
                    <br />
                    <br />
                    If you decide to sell your property yourself, this is referred to as “For Sale By Owner”. The
                    process remains the same as selling traditionally except that there is no help of a real estate
                    agent. As the property owner, you are solely responsible for your property prep, marketing,
                    viewings, and negotiating with potential buyers. Askjojo will send you some leads to help you sell
                    your property fast.
                    <br />
                    <br />
                    <span className="bold">Why should you sell your property by yourself?</span>
                    <ul className="why">
                      <li>When selling by yourself, you avoid paying an agent commission</li>
                      <li>You get buyer leads sent directly to you from askjojo</li>
                      <li>You have flexibility and control from start to successful sale</li>
                    </ul>
                  </p>
                  <p className="text-left">
                    <Link to="/get">
                      <Button type="button" className="hero-btn col-md-4 col-xs-12">
                        Receive Leads
                      </Button>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SellPage
