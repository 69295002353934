import React from 'react'
import {Form, Button} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import wiredBlue from 'assets/images/wired-blue.gif'

const copy = {
  propertyTypeHeading: '2. What type of property are you looking for?',
  propertyTypeHelpText: ` What are your spatial needs? For family or just you and a friend?`,
  next: 'Next',
  back: 'Back',
}

const StepPropertyType = ({handleChange, handleBlur, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">{copy.propertyTypeHeading}</h3>
      <Form.Group>
        <Form.Control as="select" name="propertyType" onChange={handleChange}>
          <option>Please select</option>
          <option value="flat-apartment">Flat/Apartment</option>
          <option value="townhouse">Townhouse</option>
          <option value="house">House</option>
          <option value="farm">Farm</option>
          <option value="Commercial">Commercial</option>
          <option value="Industrial">Industrial</option>
          <option value="vacant-land">Vacant Land</option>
          <option value="other">Other</option>
        </Form.Control>
      </Form.Group>
      {values?.propertyType === 'other' && (
        <div className="form-group">
          <Form.Control
            type="text"
            name="propertyTypeOther"
            value={values.propertyTypeOther}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Type your answer"
          />
        </div>
      )}

      <div className="explaina text-center col-md-8 offset-md-2">{copy.propertyTypeHelpText}</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredBlue} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps">
            <div className="rate" style={{width: '10%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back" onClick={previous}>
        {copy.back}
      </Button>
      <Button variant="primary" className="next" onClick={next} disabled={!values?.propertyType}>
        {copy.next}
      </Button>
    </div>
  )
}

export default StepPropertyType
