import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

const AboutPage = () => {
  return (
    <section id="work" className="top-60">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="heading">
              About Us
              <br />
            </h1>
            <div className="col-md-6 offset-md-3 wizard white-bg go-left big-pad relative">
              <h3 className="prod-title text-center bottom-30">...</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutPage
