import React from 'react'
import {Form, Button} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import wiredBlue from 'assets/images/wired-blue.gif'

const copy = {
  petsHeading: '8. Do you have any pets?',
  petsHelpText: `Tell us about your fury friends`,
  next: 'Next',
  back: 'Back',
}

const StepPets = ({handleChange, handleBlur, values, next, previous}) => {
  return (
    <div className="col-md-12 wizard white-bg go-left big-pad relative">
      <h3 className="prod-title text-center bottom-30">{copy.petsHeading}</h3>
      <div className="form-group">
        <Form.Control as="select" name="pets" onChange={handleChange}>
          <option>Please select</option>
          <option value="no">No i dont</option>
          <option value="planning">No but i plan to get</option>
          <option value="yes">Yes i do</option>
          <option value="other">Other</option>
        </Form.Control>
      </div>

      {values?.pets === 'other' && (
        <div className="form-group">
          <input
            type="text"
            name="petsOther"
            value={values.petsOther}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Type other answer here"
          />
        </div>
      )}

      <div className="explaina text-center col-md-8 offset-md-2">{copy.petsHelpText}</div>
      <div className="col-md-12 text-center mg-bottom-20">
        <div className="col-md-12">
          <div className="col-xs-4 offset-xs-4">
            <Image src={wiredBlue} style={{margin: '0 auto', width: '50%'}} alt="" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="steps">
            <div className="rate" style={{width: '70%'}}></div>
          </div>
        </div>
      </div>
      <Button variant="" className="back" onClick={previous}>
        {copy.back}
      </Button>
      <Button variant="primary" className="next" onClick={next}>
        {copy.next}
      </Button>
    </div>
  )
}

export default StepPets
