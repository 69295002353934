import React from 'react'
import CategorySection from './CategorySection'
import FindWithJojoSection from './FindWithJojoSection'

const HomePage = () => (
  <>
    <CategorySection />
    <FindWithJojoSection />
  </>
)

export default HomePage
